import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "drop-down-menus"
    }}>{`Drop-down Menus`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Menu items should be written in title case (first letter of each word capitalized, except
prepositions and articles).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The list of options should be sorted in a consistent order (alphabetical, chronological, the most
selected option, or by order of importance).`}</p>
      </li>
    </ul>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/radio-buttons"
      }}>{`Radio Buttons`}</a>{`,
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/checkboxes"
      }}>{`Check Boxes`}</a>{`, and
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/instructional-text"
      }}>{`Instructional Text`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      